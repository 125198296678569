<template>
    <div class="modal-quiz">
        <div class="head">
            <div class="flex-row flex-between items-start">
                <div class="left">
                    <div class="title" v-html="$translate('MODAL_QUIZ_TITLE')" />
                </div>
                <i @click="$emit('close')" class="zmdi zmdi-close" />
            </div>
            <div class="title-desc" v-html="$translate('MODAL_QUIZ_DESC')" />
        </div>
        <div class="hr" />
        <div class="body">
            <div class="badge-quiz">
                <span class="f-bold c-text-dark">Quiz {{ cur + 1 }} / </span>
                <span>{{ numQuizzes }}</span>
            </div>
            <div class="quiz-title" v-html="quizzes[cur].title" />
            <div class="questions">
                <div
                    @click="onClickQuestion(question)"
                    class="question-title"
                    :class="{ selected: question.$$selected }"
                    :key="question.id"
                    v-for="question in quizzes[cur].questions"
                    v-html="question.title"
                />
            </div>
            <button
                @click="checkAnswer"
                class="btn btn-brd"
                :class="{ primary: questionSelected }"
                v-html="$translate('CHECK_QUIZ_ANSWER')"
            />
        </div>
    </div>
</template>
<script>
import agentService from '@/services/agent'

export default {
    name: 'ModalQuiz',
    props: ['options'],
    data: () => ({
        cur: 0,
    }),
    computed: {
        quizzes() {
            return (this.options || {}).quizzes || []
        },
        questionSelected() {
            return this.quizzes[this.cur].questions.some(question => question.$$selected)
        },
        numQuizzes() {
            return (this.quizzes || []).length || 0
        },
    },
    methods: {
        checkAnswer() {
            const onCorrectAnswer = async quiz => {
                quiz.is_solved = true
                try {
                    await agentService.solveQuiz(quiz)
                    if (this.cur + 1 < this.numQuizzes) {
                        this.$toast.success('QUIZ_CORRECT_ANSWER')
                        this.cur++
                    } else {
                        this.$toast.success('QUIZ_ALL_SOLVED')
                        this.$store.dispatch('loadNotices')
                        this.$emit('close')
                    }
                } catch (e) {}
            }

            const correct = this.quizzes[this.cur].questions.some(question => question.$$selected && question.is_answer)
            if (correct) {
                onCorrectAnswer(this.quizzes[this.cur])
            } else {
                this.$toast.error('QUIZ_WRONG_ANSWER')
            }
        },
        onClickQuestion(question) {
            const quiz = this.quizzes.find(quiz => quiz.id === question.quiz_id)
            if (!quiz) return

            ;(quiz.questions || []).forEach(q => this.$set(q, '$$selected', q.id === question.id))
        },
    },
}
</script>
